import { Divider, HoverCard, Space } from "@mantine/core";
import { CustomCellRendererProps } from "ag-grid-react";
import { CellValue, DetailedCellError, SimpleCellAddress } from "hyperformula";
import moment from "moment";
import React from "react";
import { GridRowDataType } from "src/classes/GridState";
import { excelColToColIndex, HoverLabel } from "src/classes/RenderedDoc";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { GridCellObject } from "./GridCellObject";
import { HFCellRenderContext } from "./HFCellRenderContext";
import { ConfidenceIndicators } from "./ConfidenceIndicators";
import { useSelector } from "react-redux";
import { featureFlagSelectors } from "src/redux/selectors/feature-flag.selectors";
import InfoIcon from "@mui/icons-material/Info";

const mapConfidenceToIndicator = (
  confidence: number | null,
  mediumThreshold: number,
  lowThreshold: number,
): ConfidenceIndicators => {
  if (confidence === -1 || confidence === 0 || confidence === null || confidence === undefined) {
    return ConfidenceIndicators.NO_CONFIDENCE;
  } else if (confidence === 1 || confidence >= mediumThreshold) {
    return ConfidenceIndicators.COMPLETE_CONFIDENCE;
  } else if (confidence < mediumThreshold && confidence > lowThreshold) {
    return ConfidenceIndicators.HIGH_CONFIDENCE;
  } else if (confidence < lowThreshold) {
    return ConfidenceIndicators.LOW_CONFIDENCE;
  }
  return ConfidenceIndicators.NO_CONFIDENCE;
};

export const HFCellRenderer = (props: CustomCellRendererProps<any, any, HFCellRenderContext>) => {
  const { context, column, node } = props;
  const {
    sheetId,
    tab,
    rawSheetData,
    calculatedSheetData: sheetData,
    hoverInfoData,
    confidenceData,
  } = context as HFCellRenderContext;

  const rowIdx = node.rowIndex;
  const colId = column?.getColId() as string;
  const colIdx = excelColToColIndex(colId);
  const cellData: GridCellObject | undefined = node.data[colId];
  const featureFlagsFlags = useSelector(featureFlagSelectors);
  const lowConfidenceThreshold = featureFlagsFlags?.featureFlags?.LOW_CONFIDENCE_THRESHOLD;
  const mediumConfidenceThreshold = featureFlagsFlags?.featureFlags?.MEDIUM_CONFIDENCE_THRESHOLD;

  if (!cellData) {
    return null;
  }

  const hoverInfo = !rowIdx
    ? { type: "auto" }
    : (hoverInfoData?.[tab]?.[rowIdx]?.[colIdx] ?? { type: "auto" });

  const cellAddress: SimpleCellAddress = {
    sheet: sheetId,
    col: colIdx,
    row: rowIdx || 0,
  };

  const { formula, value } = {
    formula: rawSheetData?.[tab]?.[cellAddress.row]?.[colIdx],
    value: sheetData?.[tab]?.[cellAddress.row]?.[colIdx],
  };

  const confidence = confidenceData?.[tab]?.[cellAddress.row]?.[colIdx];
  const dataConfidence = mapConfidenceToIndicator(
    confidence as number,
    mediumConfidenceThreshold.value,
    lowConfidenceThreshold.value,
  );

  const cellDataType = cellData?.type;
  const startsWithEquals = typeof formula === "string" && formula.startsWith("=");

  const _toRender = cellDataType === "date" || !startsWithEquals ? formula : value;
  const toRender =
    _toRender === undefined
      ? ""
      : _toRender instanceof Date
        ? moment(_toRender).format("MM-DD-YYYY")
        : _toRender;
  const displayValue = getDisplayValue(toRender, cellDataType);

  const rowNumberForDisplay = typeof rowIdx === "number" && rowIdx + 1;

  const confidenceElem =
    Boolean(confidence) && confidence !== 1 ? (
      <Confidence
        confidence={confidence}
        lowThreshold={lowConfidenceThreshold.value}
        mediumThreshold={mediumConfidenceThreshold.value}
      />
    ) : null;

  if (colId === "A") {
    return (
      <div style={{ position: "relative" }} className="pr-5">
        <span
          style={{
            position: "absolute",
            left: "-0.5em",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="text-xs font-light text-gray-600"
        >
          {rowNumberForDisplay}
        </span>
        <span id={`hf-value-${rowIdx}-${colId}`} className={`text-base text-gray-900 ml-5`}>
          {displayValue}
        </span>
      </div>
    );
  }

  const iconColor =
    dataConfidence === ConfidenceIndicators.HIGH_CONFIDENCE
      ? mediumConfidenceThreshold.color
      : dataConfidence === ConfidenceIndicators.LOW_CONFIDENCE
        ? lowConfidenceThreshold.color
        : "action";
  const hasConfidenceIndicator =
    typeof confidence === "number" &&
    dataConfidence !== ConfidenceIndicators.COMPLETE_CONFIDENCE &&
    dataConfidence !== ConfidenceIndicators.NO_CONFIDENCE;
  const showHoverIcon =
    featureFlagsFlags?.featureFlags?.SHOW_SPREADS_HOVER_ICON &&
    Boolean(hoverInfo.type && hasConfidenceIndicator)
      ? true
      : false;

  return (
    <>
      <span
        id={`hf-value-${rowIdx}-${colId}`}
        className="text-base text-gray-900 ml-5 w-full flex items-center justify-end"
        data-confidence={dataConfidence}
        style={{
          paddingRight: showHoverIcon ? "0" : "24px", // Add padding if info icon exists
        }}
      >
        {`${displayValue}`}
        {(hoverInfo.type === "text" && showHoverIcon && (
          <>
            <HoverCard
              openDelay={250}
              withArrow
              arrowSize={20}
              position={"right"}
              styles={{
                dropdown: {
                  width: "auto",
                  padding: "20px 24px",
                  borderRadius: "6px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  background: "#616F81",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "17px",
                  letterSpacing: "0.56px",
                },
              }}
            >
              {showHoverIcon && (
                <HoverCard.Target>
                  <span className={`ml-2 mt-1 hover:text-[#D7D7D7]`}>
                    <InfoIcon fontSize="inherit" style={{ color: iconColor }} />
                  </span>
                </HoverCard.Target>
              )}
              <HoverCard.Dropdown>
                {(hoverInfo as HoverLabel).label}
                {confidenceElem && (
                  <>
                    <Space h="sm" />
                    <Divider /> <span>{confidenceElem}</span>
                  </>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
          </>
        )) ||
          (hoverInfo.type === "auto" && (
            <>
              <HoverCard
                openDelay={250}
                withArrow
                arrowSize={20}
                position={"right"}
                styles={{
                  dropdown: {
                    width: "auto",
                    padding: "20px 24px",
                    borderRadius: "6px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    background: "#616F81",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0.56px",
                  },
                }}
              >
                {showHoverIcon && (
                  <HoverCard.Target>
                    <span className="ml-2 mt-1 text-primary-800 hover:text-[#D7D7D7]">
                      <InfoIcon fontSize="inherit" style={{ color: iconColor }} />
                    </span>
                  </HoverCard.Target>
                )}
                <HoverCard.Dropdown>
                  <>
                    <span>{confidenceElem}</span>
                  </>
                </HoverCard.Dropdown>
              </HoverCard>
            </>
          ))}
      </span>
    </>
  );
};

const Confidence = ({
  confidence,
  mediumThreshold,
  lowThreshold,
}: {
  confidence: number | null | string;
  mediumThreshold: number;
  lowThreshold: number;
}) => {
  const confidenceNumber = typeof confidence === "string" ? parseFloat(confidence) : confidence;
  const indicator = mapConfidenceToIndicator(confidenceNumber, mediumThreshold, lowThreshold);
  switch (indicator) {
    case ConfidenceIndicators.LOW_CONFIDENCE:
    case ConfidenceIndicators.HIGH_CONFIDENCE:
      return <span className="text-xs font-light">Confidence: {formatConfidence(confidence)}</span>;
    case ConfidenceIndicators.NO_CONFIDENCE:
    case ConfidenceIndicators.COMPLETE_CONFIDENCE:
    default:
      return null;
  }
};

const formatConfidence = (confidence: RawConfidenceContent): string => {
  if (typeof confidence === "number") {
    return `${confidence.toFixed(2)}%`;
  } else {
    return `${confidence}`;
  }
};

const getDisplayValue = (cellValue: CellValue, cellType: GridRowDataType): React.ReactNode => {
  if (cellValue instanceof DetailedCellError) {
    return cellValue.value.toString();
  }

  if (cellValue === null || cellValue === undefined) {
    return "";
  }

  switch (cellType) {
    case "number":
      const num = typeof cellValue === "string" ? Number(cellValue) : (cellValue as number);
      const maximumFractionDigits = num % 1 === 0 ? 0 : num < 1 ? 3 : 2;
      return num.toLocaleString("en-US", { maximumFractionDigits, useGrouping: true });
    case "percentage":
      return `${((cellValue as number) * 100).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}%`;
    case "date":
      const allowedTypes = ["number", "string", "boolean"];
      if (!allowedTypes.includes(typeof cellValue)) {
        return "";
      } else if (typeof cellValue === "number") {
        return moment(cellValue).format("MM-DD-YYYY");
      } else {
        return moment(cellValue as string).format("MM-DD-YYYY");
      }
    case "text":
    case "formula":
      return `${cellValue}`;
    case "ratio":
      return `${Number(cellValue).toFixed(2)} x`;
    default:
      return "";
  }
};
