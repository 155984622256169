import { TaxFormType } from "@interfold-ai/shared/models/tax/common";
import { TabName } from "src/redux/reducers/types";

export function formatNameForRentRoll(propertyName: string): string {
  return `RR - ${propertyName}`;
}

export function formatNameForSubject(propertyName: string): string {
  return `SUBJ - ${propertyName}`;
}

export function getSourceFromFormType(formType: TaxFormType | undefined | null): string {
  switch (formType) {
    case "1040":
      return "Personal Tax Return";
    case "1065":
      return "Partnership Tax Return (1065)";
    case "1120S":
      return "S-Corp Tax Return (1120-S)";
    case "Multiple":
      return "Multiple Tax Returns";
    default:
      return "Tax Return";
  }
}

/**
 * General Spreads Tab Type enums and TabGroup enums
 */
export enum GeneralSpreadsTabGroupEnum {
  Form1040 = "1040",
  Form1065 = "1065",
  Form1120S = "1120S",
  Form1120 = "1120",
  K1 = "K1",
  Legacy = "Legacy",
  Global = "Global",
  Financials = "Financials",
  Appraisals = "Appraisals",
}

export enum GeneralSpreadsTabTypeEnum {
  PCF_FOR_YEAR = "PCF for Year",
  K1S_FOR_YEAR = "K-1s for Year",
  PCF_ALL_YEARS = "PCF All Years",
  SCHEDULE_C_BREAKOUT = "Schedule C Breakout",
  SCHEDULE_E_BREAKOUT = "Schedule E Breakout",
  BUSINESS_IS_ALL_YEARS = "Business IS All Years",
  BUSINESS_BS_ALL_YEARS = "Business BS All Years",
  BUSINESS_CASH_FLOW_ALL_YEARS = "Business Cash Flow All Years",
  BUSINESS_RATIOS_ALL_YEARS = "Business Ratios All Years",
  FORM_8825_BREAKOUT = "Form 8825 Breakout",
  GLOBAL_CASH_FLOW = "Global Cash Flow",
  FINANCIALS = "Financials",
  FINANCIALS_CASH_FLOW = "Financials Cash Flow",
  APPRAISALS = "Appraisals",
}

/**
 * NOI Analysis Tab Type enums and TabGroup enums
 */
export enum NOIAnalysisTabGroupEnum {
  PERSONAL_TAX_RETURN_TOTAL = "Personal Tax Return Total",
  BUSINESS_TAX_RETURN_TOTAL = "Business Tax Return Total",
  JOINTLY_OWNED_PROPERTIES_TOTAL = "Jointly Owned Properties Total",
  PERSONAL_TAX_RETURN_BY_PROPERTY = "Personal Tax Return By Property",
  BUSINESS_TAX_RETURN_BY_PROPERTY = "Business Tax Return By Property",
  JOINTLY_OWNED_PROPERTIES = "Jointly Owned Properties",
  PERSONAL_TAX_RETURN_BY_YEAR = "Personal Tax Return By Year",
  BUSINESS_TAX_RETURN_BY_YEAR = "Business Tax Return By Year",
  JOINTLY_OWNED_PROPERTIES_BY_YEAR = "Jointly Owned Properties By Year",
  GROUPED_ASSETS_BY_YEAR = "Grouped Assets By Year",
  SUBJECT_ASSETS = "Subject Assets",
  SUBJECT_ASSETS_TOTAL = "Subject Assets Total",
  RENT_ROLL = "Rent Roll",
  RENT_ROLL_TOTAL = "Rent Roll Total",
}

export enum NOIAnalysisTabTypeEnum {
  PERSONAL_TAX_RETURN = "Personal Tax Return",
  BUSINESS_TAX_RETURN = "Business Tax Return",
  CUSTOM_GROUPING = "Custom Grouping",
  JOINTLY_OWNED_PROPERTIES = "Jointly Owned Properties",
  SUBJECT_ASSETS = "Subject Assets",
  RENT_ROLL = "Rent Roll",
}

export type TabGroup = {
  tabName: TabName;
  group: GeneralSpreadsTabGroupEnum | NOIAnalysisTabGroupEnum;
  fullTabName?: TabName; // NOTE(@dpow101): Making this optional for backwards compatibility
  tabType: GeneralSpreadsTabTypeEnum | NOIAnalysisTabTypeEnum;
};
