import { DocumentUploadStatus } from "@interfold-ai/db";
import { FormikValues } from "formik";
import JSZip from "jszip";
import moment from "moment";
import {
  BorrowerIntakePage,
  PageAction,
  QuestionNS,
  QuestionInput,
} from "src/models/BorrowerIntakePage";
import {
  Answer,
  AnswerInput,
  AnswerInputMultiple,
  AnswerInputSingle,
  Inputs,
} from "src/models/BorrowerIntakePage/answer";
import { DocumentRequest } from "@interfold-ai/shared/models/DocumentRequest";
import { Loan } from "src/models/Loan";
import { jsonFormatter } from "src/page-components/overview/OverviewPageWrapper/helper";
import { Review } from "src/models/Review";
import { EntityHydrated } from "src/models/entity";
import { v4 as uuidv4 } from "uuid";
import {
  AUTH0_FALLBACK_BASE_URL,
  LOCALHOST,
  STRING_LENGTH_THRESHOLD,
} from "@interfold-ai/shared/utils/constants";
import { Deps } from "@interfold-ai/shared/dependencies";
import { IncomingMessage } from "node:http";
import { NOIAnalysisProps } from "src/redux/reducers/types";
import { ExtractableDocumentType } from "@interfold-ai/shared/enums/ExtractableDocumentType";
import { CanonicalRentRoll } from "@interfold-ai/shared/models/Property";
import { RentRollTableData } from "@interfold-ai/shared/models/Property";
// Cause a type error if the argument is not of type `never`.
// Use this in the default case of a switch statement to ensure that all cases are handled.
// Does not do anything at runtime.
export const expectNever = (_arg: never): void => {};

// Return a new object with the same keys and values,
// but with the keys sorted.
// See: https://stackoverflow.com/a/31102605/12005228
export const sortKeys = <T extends object>(unordered: T): T => {
  return (Object.keys(unordered).sort() as (keyof T)[]).reduce((obj, key) => {
    obj[key] = unordered[key];
    return obj;
  }, {} as T);
};

export function isStringLengthBeyondThreshold(str: string): boolean {
  return str.length > STRING_LENGTH_THRESHOLD;
}
export function slugify(input: string): string {
  return input
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, " ")
    .replace(/[\s-]+/g, "_");
}

//SOME_THING_LIKE_THIS => some thing like this
//Some thing like this => some thing like this
export function unSlugNormalized(input: string): string {
  return input.toLowerCase().trim().replace(/_/g, " ").replace(/\s+/g, " ");
}
// How many times does `value` occur in `str`?
export const countOccurrences = (str: string, value: string) => {
  const matches = str.match(new RegExp(value, "gi"));
  return matches ? matches.length : 0;
};

export const checkInputs = (inputGroup: AnswerInput) => {
  if ("inputs" in inputGroup) {
    return inputGroup.inputs.every(checkInputs);
  }
  return Boolean(inputGroup.response);
};

export const filterValidResponses = (answers: Answer[]) => {
  return answers.filter((answer) => answer.inputs.every((inputGroup) => checkInputs(inputGroup)));
};

// TODO: move
export const createRequestData = (formikValues: any, question: any, isPage3?: boolean): Answer => {
  switch (question?.type) {
    case "single":
      const singleAnswer: Answer = {
        questionId: question.id,
        inputs: [],
      };
      for (let i = 0; i < question.inputs.length; i++) {
        singleAnswer.inputs.push({
          text: question.inputs[i].title,
          response: formikValues?.[question.id]?.[question.inputs[i].role],
          role: question.inputs[i].role ?? "",
        });
      }
      return singleAnswer;

    case "multiple":
      const answer: Answer = {
        questionId: question.id,
        inputs: [],
      };
      const arr = [];
      for (const Akey in formikValues) {
        if (!isPage3) {
          continue;
        }
        arr.push(...formikValues[Akey]);
      }
      const subResponses = isPage3 ? [] : formikValues[question.subQuestions[0].id];
      for (let i = 0; i < question.subQuestions.length; i++) {
        if (!isPage3) {
          continue;
        }
        subResponses[i] = formikValues[question.subQuestions[i].id]?.map((e: any) => e);
      }

      for (let z = 0; z < subResponses.length; z++) {
        const partialSubQuestion = question.subQuestions[z]
          ? {
              title: question.subQuestions[z].title,
              icon: question.subQuestions[z].icon,
            }
          : undefined;
        const subResponse = subResponses[z];
        const subAnswer: any = {
          inputs: [],
          ...("subRec" in subResponse ? { subForm: [] } : {}),
          ...(isPage3 ? { subQuestion: partialSubQuestion } : {}),
        };

        if (isPage3) {
          for (let i = 0; i < subResponse.length; i++) {
            const lastSubAnswer: AnswerInputMultiple = { inputs: [] };
            for (let x = 0; x < Object.entries(subResponse[i]).length; x++) {
              (lastSubAnswer.inputs as AnswerInputSingle[]).push({
                text: question.subQuestions[z].inputs[x].title,
                response: subResponse[i]?.[question.subQuestions[z].inputs[x].role] as string,
                role: question.subQuestions[z].inputs[x].role,
              });
            }
            subAnswer.inputs.push(lastSubAnswer);
          }
        } else {
          for (let i = 0; i < Object.entries(subResponse).length; i++) {
            subResponse?.[question.subQuestions[0].inputs[i]?.role] !== undefined &&
              question.subQuestions[0].inputs[i].type !== "filepicker" &&
              subAnswer.inputs.push({
                text: question.subQuestions[0].inputs[i].title,
                response: subResponse?.[question.subQuestions[0].inputs[i].role] as string,
                role: question.subQuestions[0].inputs[i].role,
              });
          }
        }
        if ("subRec" in subResponse) {
          // TODO: to be changed for more than 1 recursive level
          for (let i = 0; i < Object.entries(subResponse?.["subRec"]).length; i++) {
            const subFormByIndex: any = { inputs: [] };
            for (let y = 0; y < Object.keys(subResponse?.["subRec"][i]).length; y++) {
              subFormByIndex.inputs.push({
                text: question.subQuestions[0].inputs[y]?.title,
                response: subResponse?.["subRec"][i][
                  question.subQuestions[0].inputs[y]?.role
                ] as string,
                role: question.subQuestions[0].inputs[y]?.role,
              });
            }
            subAnswer.subForm?.push(subFormByIndex);
          }
        }
        answer.inputs.push(subAnswer);
      }
      return answer;
    default:
      throw new Error(`BUG: unexpected question type ${question.type}`);
  }
};

export const pageActionCreator = (
  pageAction: PageAction,
  overviewAnswer: Inputs,
  overviewPages: BorrowerIntakePage[],
): { pageAction: PageAction; pageAnswer: any } => {
  if (pageAction.section === "overview" && pageAction.page === 3) {
    return {
      pageAction: {
        ...pageAction,
        questions: jsonFormatter(
          // @ts-ignore because type string can't be used as index type
          overviewAnswer?.["1"]?.[overviewPages[0].questions[0].id]?.["entity-type"] !==
            "Person/Household"
            ? // @ts-ignore because type string can't be used as index type
              overviewAnswer?.["2"]?.[overviewPages[1].questions[0]?.subQuestions?.[0].id ?? ""]
            : // @ts-ignore because type string can't be used as index type
              overviewAnswer?.["1"]?.[overviewPages[0].questions[0]?.id ?? ""],
          // @ts-ignore because type string can't be used as index type
          overviewAnswer?.["1"]?.[overviewPages[0].questions[0].id]?.["entity-name"],
        ).questions,
      },
      pageAnswer: {
        ...(overviewAnswer?.["3"] ? overviewAnswer?.["3"] : {}),
      },
    };
  }
  return {
    pageAction: {
      bgColor: "",
      label: "",
      page: 0,
      questions: [],
      section: "overview",
    },
    pageAnswer: {},
  };
};

export const isFilePickerDisabled = (documentRequests: DocumentRequest[], input: QuestionInput) => {
  let isDisabled = false;
  if (documentRequests.length && input.type === "filepicker") {
    const documentRequest = documentRequests.find(
      (documentRequest) => documentRequest.type.id === input.documentTypeId,
    );
    if (documentRequest) {
      const status = documentRequest.documentUpload[0].status;
      if (status === DocumentUploadStatus.WAIVED) {
        isDisabled = true;
      }
    }
  }
  return isDisabled;
};

export const doesDocumentRequestContainWaivedStatusDocument = (
  documentRequests: DocumentRequest[],
): boolean => {
  return documentRequests.some((documentRequest) =>
    documentRequest.documentUpload.some((upload) => upload.status === DocumentUploadStatus.WAIVED),
  );
};

export const getListOfWaivedDocumentsPerPage = (
  question: any,
  loanId: number,
  loans: Loan[],
  entityId?: number,
  entityName?: string,
): string[] => {
  let documents: string[] = [];
  question.inputs.map((input: QuestionInput) => {
    if (input.type === "filepicker" && input.documentTypeId) {
      let currentLoanObject: Loan | undefined;
      if (loanId && loans.length) {
        currentLoanObject = loans.find((loan) => loan.id === loanId);
      }

      if (currentLoanObject) {
        const entities = currentLoanObject.entities;
        const currentEntity = entities.find(
          (entity) =>
            entity.id === entityId ||
            (entity.asCompany
              ? entity.asCompany.name === entityName
              : entity.asIndividual && entity.asIndividual.name === entityName),
        );
        if (currentEntity) {
          const documentRequest = currentEntity.documentRequests.find(
            (documentRequest) => documentRequest.type.id === input.documentTypeId,
          );
          if (documentRequest) {
            const status = documentRequest.documentUpload[0].status;
            if (status === DocumentUploadStatus.WAIVED) {
              documents.push(`${documentRequest.type.year} ${documentRequest.type.name}`);
            }
          }
        }
      }
    }
  });

  return documents;
};

export const areAllDocumentsCompleted = (
  formikValues: FormikValues,
  selectedPage: BorrowerIntakePage,
  documentRequests: DocumentRequest[],
) => {
  let result = true;
  selectedPage?.questions.forEach((question) => {
    if (question.allFilePickers && question.type == "single") {
      (question as QuestionNS.SingleSection).inputs.forEach((input) => {
        let filePickerStatus = isFilePickerDisabled(documentRequests, input);
        if (!formikValues?.[question.id]?.[input.role] && input.required) {
          if (!filePickerStatus) {
            result = false;
          }
        }
      });
    } else if (
      question.type === "multiple" &&
      question.subQuestions[0].inputs.find((input) => input.type === "filepicker")
    ) {
      formikValues?.[question.subQuestions[0].id].forEach((item: any) => {
        if (
          !item?.[
            question.subQuestions[0].inputs.find((input) => input.type === "filepicker")
              ?.role as string
          ]
        ) {
          question.subQuestions[0].inputs.forEach((input) => {
            if (input.type === "filepicker") {
              let filePickerStatus = isFilePickerDisabled(documentRequests, input);
              if (input?.role && input?.required) {
                if (!filePickerStatus) {
                  result = false;
                }
              }
            }
          });
        }
      });
    }
  });
  return result;
};

/**
 * findInValues recursively searches an object
 * and its nested sub-objects and arrays
 * for values that match a given predicate.
 * The function yields matching values as a generator,
 * allowing the consumer to process them one by one.
 *
 * @param obj The object to be searched.
 * @param match A matcher function that takes a value from `obj` and returns a value of type T or undefined.
 *                  If the matcher returns a value of type T, it will be included in the search results.
 *                  If the matcher returns undefined, the item will be excluded from the search results.
 * @returns A generator that yields matching values of type T as they are found.
 *
 * @example
 * const obj = {
 *   a: 1,
 *   b: {
 *     c: 2,
 *     d: {
 *       e: 'hello',
 *       f: 3,
 *     },
 *   },
 * };
 *
 * const predicate = (item: unknown): number | undefined => {
 *   return typeof item === 'number' ? item : undefined;
 * };
 *
 * const result = Array.from(findInValues(obj, predicate));
 * // result will be [1, 2, 3]
 */
export function* findInValues<T>(
  obj: object,
  match: (item: unknown) => T | undefined,
): Generator<T, void> {
  function* findRecursively(currentObj: object): Generator<T, void> {
    for (const value of Object.values(currentObj)) {
      if (typeof value === "object" && value !== null) {
        yield* findRecursively(value);
      } else {
        const result = match(value);
        if (result !== undefined) {
          yield result;
        }
      }
    }
  }

  yield* findRecursively(obj);
}

export const fieldInitialValue = (
  type:
    | "text"
    | "segments"
    | "date"
    | "radio"
    | "dropdown"
    | "checkbox"
    | "checkbox-group"
    | "filepicker"
    | "text-area"
    | "bulk-upload",
) => {
  switch (type) {
    case "text":
      return "";
    case "filepicker":
      return undefined;
    case "checkbox":
      return false;
    case "checkbox-group":
      return false;
    default:
      return "";
  }
};

export const getFormattedDateTime = (
  dateTimeString: string,
  format: string,
  inputFormat?: string,
) => {
  let parsedDate;
  if (inputFormat) {
    // Use the provided input format to parse the date string
    parsedDate = moment(dateTimeString, inputFormat);
  } else {
    // Attempt to parse the date string using ISO 8601 or RFC 2822 format
    parsedDate = moment(dateTimeString);
  }

  // Check if the date is valid
  if (!parsedDate.isValid()) {
    console.warn(`Invalid date: ${dateTimeString}`);
    return "Invalid date";
  }

  return parsedDate.format(format);
};

export const openSaveFileDialog = (data: any, filename: string, mimetype: string) => {
  const blob = new Blob([...data], { type: mimetype || "application/octet-stream" });

  const lnk = document.createElement("a");
  const url = window.URL;
  const objectURL = url.createObjectURL(blob);

  lnk.type = mimetype;
  lnk.download = filename || "untitled";
  lnk.href = objectURL;
  lnk.dispatchEvent(new MouseEvent("click"));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

export const downloadFile = (file: File | Blob, filename: string) => {
  const url = window.URL;
  const objectURL = url.createObjectURL(file);

  const lnk = document.createElement("a");
  lnk.download = filename || "untitled";
  lnk.href = objectURL;
  lnk.dispatchEvent(new MouseEvent("click"));

  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

export function FCNDocNameMapper(documentType: string): string {
  const words = documentType.replace(/[^A-Za-z0-9 ]/g, "").split(" ");
  return words.map((w) => w[0].toUpperCase() + w.slice(1).toLowerCase()).join("");
}

// Is there more than one unique item in this array?
// Example:
//  [1, 1, 1] => false
//  [1, 1, 2] => true
export const hasMoreThanOneUniqueElement = <T>(arr: T[]): boolean => {
  const set = new Set(arr);
  return set.size !== 1;
};

export const calculateTotalDocumentUploadsFromEntities = (entities: EntityHydrated[]): number => {
  return entities.reduce((total, entity) => {
    return (
      total +
      entity.documentRequests.reduce(
        (entityTotal, request) =>
          entityTotal + (request.documentUpload ? request.documentUpload.length : 0),
        0,
      )
    );
  }, 0);
};

export const selectEarliestReviewBunch = (
  reviewFromUserContext?: Review[],
  reviewAfterModification?: Review[],
): Review[] => {
  // Check if either reviewFromUserContext or reviewAfterModification is undefined, and return the other one.
  if (!reviewFromUserContext) {
    return reviewAfterModification || [];
  } else if (!reviewAfterModification) {
    return reviewFromUserContext;
  }

  const entitiesFromUserContext = reviewFromUserContext.flatMap((review) => review.entities);
  const entitiesAfterModification = reviewAfterModification.flatMap((review) => review.entities);

  // Both reviewFromUserContext and reviewAfterModification are defined.
  // Calculate the total number of Document Uploads for each entity.
  const totalUploadsOld = calculateTotalDocumentUploadsFromEntities(entitiesFromUserContext);
  const totalUploadsNew = calculateTotalDocumentUploadsFromEntities(entitiesAfterModification);

  // Compare the total number of Document Uploads and return the one with more uploads.
  if (totalUploadsOld >= totalUploadsNew) {
    return reviewFromUserContext;
  } else {
    return reviewAfterModification;
  }
};

export const selectEarliestLoanBunch = (
  loanFromUserContext?: Loan[],
  loanAfterModification?: Loan[],
): Loan[] => {
  // Check if either loanFromUserContext or loanAfterModification is undefined, and return the other one.
  if (!loanFromUserContext) {
    return loanAfterModification || [];
  } else if (!loanAfterModification) {
    return loanFromUserContext;
  }

  const entitiesFromUserContext = loanFromUserContext.flatMap((review) => review.entities);
  const entitiesAfterModification = loanAfterModification.flatMap((review) => review.entities);

  // Both loanFromUserContext and loanAfterModification are defined.
  // Calculate the total number of Document Uploads for each entity.
  const totalUploadsOld = calculateTotalDocumentUploadsFromEntities(entitiesFromUserContext);
  const totalUploadsNew = calculateTotalDocumentUploadsFromEntities(entitiesAfterModification);

  // Compare the total number of Document Uploads and return the one with more uploads.
  if (totalUploadsOld >= totalUploadsNew) {
    return loanFromUserContext;
  } else {
    return loanAfterModification;
  }
};

export function generateZipFileName(fileArray: File[]): string {
  return `${fileArray.map((file) => file.name).join(" & ")}.zip`;
}

// A helper method used to convert an array of files into a single zip file
export async function zipFiles(fileArray: File[], fileName: string): Promise<File> {
  const zip = new JSZip();

  const readAsArrayBuffer = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  for (const file of fileArray) {
    const buffer: any = await readAsArrayBuffer(file);
    zip.file(file.name, buffer);
  }

  const blob = (await zip.generateAsync({ type: "blob" })) as unknown as any;
  return new File([blob], fileName || generateZipFileName(fileArray), {
    type: blob.type,
    lastModified: new Date().getTime(),
  });
}

export function findDuplicates(array: number[]): number[] {
  const seen: Set<number> = new Set();
  const duplicates: number[] = [];

  for (const element of array) {
    if (!seen.has(element)) {
      seen.add(element);
    } else {
      duplicates.push(element);
    }
  }

  return duplicates;
}

export const momentUtcOrUndefined = (date: moment.MomentInput) => {
  return date ? moment.utc(date) : undefined;
};

export const generateUniqueIdentifier = (): string => {
  return uuidv4();
};

export const lenderIdFromRequest = (deps: Deps<"logger">, req: IncomingMessage) => {
  const domainNameMatchesLender = (domainName: string, lenderName: string) => {
    return (
      domainName.includes(`${lenderName}.app`) ||
      domainName.includes(`${lenderName}.dev`) ||
      domainName.includes(`${lenderName}-standalone-app`) ||
      domainName.includes(`${lenderName}-app-standalone`)
    );
  };

  // NOTE: When adding new lenders, ensure you keep the domain check here
  // up-to-date with all our supported lenders, so the app is able to find
  // the correct lenderID.
  const lenderIdFromDomainName = (domainName: string | undefined) => {
    deps.logger.trace({ method: "lenderIdFromDomainName", params: { domainName } });
    if (!domainName) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "default: no domain" });
      return "1";
    }
    if (domainNameMatchesLender(domainName, "fcn")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "fcn" });
      return "3";
    }
    if (
      domainNameMatchesLender(domainName, "primealliance") ||
      domainNameMatchesLender(domainName, "pab")
    ) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "primealliance | pab" });
      return "4";
    }
    if (
      domainNameMatchesLender(domainName, "lnb") ||
      domainNameMatchesLender(domainName, "lamar")
    ) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "lamar || lnb" });
      return "5";
    }
    if (domainNameMatchesLender(domainName, "cbtx")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "cbtx" });
      return "6";
    }
    if (domainNameMatchesLender(domainName, "acb")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "acb" });
      return "7";
    }
    if (domainNameMatchesLender(domainName, "ncnb")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "ncnb" });
      return "10";
    }

    if (domainNameMatchesLender(domainName, "fccb")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "fccb" });
      return "11";
    }

    if (domainNameMatchesLender(domainName, "bp")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "bp" });
      return "14";
    }

    if (domainNameMatchesLender(domainName, "sandbox")) {
      deps.logger.trace({ method: "lenderIdFromDomainName", result: "sandbox" });
      return process.env.SANDBOX_DOMAIN_TO_LENDER_ID || "1";
    }

    if (domainName === "localhost:3000") {
      return "1";
    }

    deps.logger.warn({
      method: "lenderIdFromDomainName",
      result: `default: ${domainName} unhandled`,
    });

    return "1";
  };

  deps.logger.trace(
    {
      "x-forwarded-host": req.headers["x-forwarded-host"],
      host: req.headers.host,
      method: "lenderIdFromDomainName",
    },
    "finding lender ID from headers",
  );

  // This header is added by Cloudflare using a Transform Rule
  // See Rules > Transform Rules > Modify Request Header
  const forwardedHost = req.headers["x-forwarded-host"];

  if (forwardedHost && typeof forwardedHost === "string") {
    return lenderIdFromDomainName(forwardedHost);
  } else {
    // TODO: I think this branch can be removed;
    //  host isn't going to be the correct value (running on Render with Cloudflare)
    const hostIndex = req.rawHeaders.indexOf("host");
    let hostValue;
    if (hostIndex > -1) {
      hostValue = req.rawHeaders[hostIndex + 1];
    }
    deps.logger.info({ hostValue, result: lenderIdFromDomainName(hostValue) });
    return lenderIdFromDomainName(hostValue);
  }
};

export const domainFromRequest = (req: IncomingMessage) => {
  let domain: string | undefined;
  const forwardedHost = req.headers["x-forwarded-host"];

  if (forwardedHost && typeof forwardedHost === "string") {
    domain = forwardedHost;
  } else {
    const hostIndex = req.rawHeaders.indexOf("host");
    let hostValue;
    if (hostIndex > -1) {
      hostValue = req.rawHeaders[hostIndex + 1];
    }
    domain = hostValue;
  }

  if (domain && !domain.includes(LOCALHOST)) {
    return `https://${domain}`;
  } else {
    return AUTH0_FALLBACK_BASE_URL;
  }
};

export const addToCategory = (
  acc: NOIAnalysisProps,
  val: CanonicalRentRoll | RentRollTableData,
  year: number,
  extractorType: ExtractableDocumentType,
  useLLMForRentRoll: boolean = false,
) => {
  const yearStr = year.toString();
  if (
    [ExtractableDocumentType.MULTI_PROPERTY_RENTROLL, ExtractableDocumentType.RENT_ROLL].includes(
      extractorType,
    )
  ) {
    if (useLLMForRentRoll) {
      const rr = val as CanonicalRentRoll;
      acc.rentRolls[yearStr] = acc.rentRolls[yearStr] || [];
      acc.rentRolls[yearStr].push(rr);
    } else {
      const lr = val as RentRollTableData;
      acc.legacyRentRolls[yearStr] = acc.legacyRentRolls[yearStr] || [];
      acc.legacyRentRolls[yearStr].push(lr);
    }
  }
};

export type DocumentAttributesForKeyGeneration = {
  page: number;
  documentTypeId: string | number;
  documentYear: string;
  formIndex?: string | number;
  displayName?: string;
};

export const genUploadedDocumentKey = (
  documentAttributes: DocumentAttributesForKeyGeneration,
): number => {
  const { page, documentTypeId, documentYear, formIndex, displayName } = documentAttributes;
  const formIndexStr = !!formIndex ? formIndex : formIndex === 0 ? formIndex : "";
  const displayNameStr = displayName
    ? displayName
        .split("")
        .map((c) => c.charCodeAt(0))
        .join("")
    : "";

  return +`${page}${documentTypeId}${documentYear}${formIndexStr}${displayNameStr}`;
};
