import { createSelector } from "@reduxjs/toolkit";
import { lenderStateSelector, loanStateSelector, ocrStateSelector } from "./app.selectors";

import { JobId } from "aws-sdk/clients/textract";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";
import { addToCategory } from "src/utils/helpers";
import { ExtractableDocumentType } from "@interfold-ai/shared/enums/ExtractableDocumentType";
import { NOIAnalysisProps } from "src/redux/reducers/types";
import { FileReference } from "src/pages/api/documents/ocr/common";
import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { TaxFormYear } from "@interfold-ai/shared/models/tax/common";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { TaxProperty } from "@interfold-ai/shared/models/tax/schedules/ScheduleE";
import { CanonicalRentRoll } from "@interfold-ai/shared/models/Property";
import { spreadConfig } from "@interfold-ai/shared/spreads-config";
import { SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig";
import { spreadablePropertyLabel } from "src/redux/selectors/spreadablePropertyLabel";
import { RentalRealEstateIncomeAndExpenses } from "@interfold-ai/shared/models/tax/Form8825";
import { RentRollTableData, IncomeAssetParts } from "@interfold-ai/shared/models/Property";

export const dataNeededSelector = createSelector(
  [ocrStateSelector],
  (ocrState): FileReference[] => ocrState.dataNeeded,
);
export const runningJobsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): Record<JobId | number, FileReference> => ocrState.jobs ?? {},
);
export const allDataSelector = createSelector([ocrStateSelector], (ocrState): ExtractOutput[] =>
  ocrState.allExtracts ? Object.values(ocrState.allExtracts) : [],
);

export const cachedExtractsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): Record<JobId, ExtractOutput> => ocrState.cachedExtracts ?? {},
);

export const currentlyPollingSelector = createSelector(
  [ocrStateSelector],
  (ocrState): boolean => ocrState.isPolling,
);

export const availableUploadIds = createSelector(
  [ocrStateSelector],
  (ocrState): number[] => ocrState.availableUploadIds,
);

export const selectedUploadIdsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): number[] => ocrState.selectedUploadIds,
);
export const historicalDataSelector = createSelector(
  [ocrStateSelector, lenderStateSelector],
  (ocrState, lenderState) =>
    ocrState.allExtracts
      ? Object.values(ocrState.allExtracts).reduce(
          (acc, extractOutput) => {
            const lenderId =
              lenderState.data.find((employee) => employee.employerId)?.employerId || null;
            const lenderSpreadSettings = lenderId
              ? spreadConfig.lenderSettings[lenderId as SupportedLenderId]
              : null;
            const { useLLMForRentRoll } = lenderSpreadSettings || { useLLMForRentRoll: false };
            const extractBase = extractOutput.output;
            const source = extractOutput.source;
            const processIncomeAssetParts = (val: IncomeAssetParts | any) => {
              const year = val.year || new Date().getFullYear() - 1;
              if (
                source === ExtractableDocumentType.MULTI_PROPERTY_RENTROLL ||
                source === ExtractableDocumentType.RENT_ROLL
              ) {
                if (useLLMForRentRoll) {
                  addToCategory(acc, val, year, source, true);
                  acc.subjectAssets.push(val as CanonicalRentRoll);
                }
                {
                  addToCategory(acc, val, year, source);
                  acc.legacySubjectAssets.push(val as RentRollTableData);
                }
              } else if (
                source === ExtractableDocumentType.PERSONAL_TAX_RETURN ||
                source === ExtractableDocumentType.PERSONAL_TAX_RETURN_PROPERTY
              ) {
                const form1040 = val as Form1040;
                const taxYear = year.toString() as TaxFormYear;
                if (!acc.personalTaxReturns[taxYear]) {
                  acc.personalTaxReturns[taxYear] = [];
                }
                acc.personalTaxReturns[taxYear]?.push(form1040);
              } else if (
                source === ExtractableDocumentType.FORM_1065 ||
                source === ExtractableDocumentType.FORM_1120S ||
                source === ExtractableDocumentType.CORPORATE_TAX_RETURN ||
                source === ExtractableDocumentType.CORPORATE_TAX_RETURN_PROPERTY
              ) {
                const form = val as Form1065 | Form1120S;
                const taxYear = year.toString() as TaxFormYear;
                if (!acc.businessTaxReturns[taxYear]) {
                  acc.businessTaxReturns[taxYear] = [];
                }
                acc.businessTaxReturns[taxYear]?.push(form);
              }
            };
            if (Array.isArray(extractBase)) {
              extractBase.forEach(processIncomeAssetParts);
            } else {
              processIncomeAssetParts(extractBase);
            }
            return acc;
          },
          {
            lenderId: lenderState.data.find((employee) => employee.employerId)?.employerId || null,
            rentRolls: {},
            personalTaxReturns: {},
            subjectAssets: [],
            businessTaxReturns: {},
            legacyRentRolls: {},
            legacySubjectAssets: [],
            propertyGroups: {},
          } as NOIAnalysisProps,
        )
      : null,
);

export const candidateSubjectPropertySelector = createSelector(
  [historicalDataSelector, lenderStateSelector],
  (historicalData, lenderState) => {
    const lenderId = lenderState.data.find((employee) => employee.employerId)?.employerId || null;
    const lenderSettings = spreadConfig.lenderSettings[lenderId as SupportedLenderId];
    const { useLLMForRentRoll } = lenderSettings || { useLLMForRentRoll: false };
    if (useLLMForRentRoll) {
      return (
        Object.values(historicalData?.rentRolls || {})
          .flat()
          // Filter out duplicate property Names
          .filter(
            (val, index, self) =>
              self.findIndex((t) => t?.streetAddress === val?.streetAddress) === index,
          ) as CanonicalRentRoll[]
      );
    } else {
      return (
        Object.values(historicalData?.legacyRentRolls || {})
          .flat()
          // Filter out duplicate property Names
          .filter(
            (val, index, self) =>
              self.findIndex((t) => t?.propertyName === val?.propertyName) === index,
          ) as RentRollTableData[]
      );
    }
  },
);

export type SpreadableProperty = (
  | CanonicalRentRoll
  | RentRollTableData
  | TaxProperty
  | RentalRealEstateIncomeAndExpenses
) & {
  propertyType?:
    | "TaxProperty"
    | "LegacyRentRoll"
    | "RentalRealEstateIncomeAndExpenses"
    | "CanonicalRentRoll";
};

export const candidateAssetSelector = createSelector(
  [historicalDataSelector],
  (historicalData: NOIAnalysisProps | null) => {
    const useLLMForRentRoll =
      spreadConfig.lenderSettings[historicalData?.lenderId ?? 2].useLLMForRentRoll ?? false;
    const ptrs = Object.values(historicalData?.personalTaxReturns || {}).flat();
    const btrs = Object.values(historicalData?.businessTaxReturns || {}).flat();
    const rentRolls: CanonicalRentRoll[] = Object.values(historicalData?.rentRolls || {})
      .flat()
      .map((rentRoll) => ({
        ...rentRoll,
        propertyType: "CanonicalRentRoll",
      }));

    const legacyRentRolls: RentRollTableData[] = Object.values(
      historicalData?.legacyRentRolls || {},
    ).flat() as RentRollTableData[];

    const personalTaxReturnData = ptrs
      .map((form1040: Form1040) =>
        form1040?.schedules?.scheduleE?.properties?.map((property) => ({
          ...property,
          streetAddress: property.propertyAddress,
          propertyType: "TaxProperty",
        })),
      )
      .flat() as TaxProperty[];

    const businessTaxReturnData = btrs
      .map((form1065: Form1065 | Form1120S) =>
        form1065?.form8825?.propertyData?.map((property) => ({
          ...property,
          streetAddress: property.propertyAddress,
          propertyType: "RentalRealEstateIncomeAndExpenses",
        })),
      )
      .flat();

    const legacyRentRollData = !useLLMForRentRoll
      ? legacyRentRolls.map((legacyRentRoll) => ({
          ...legacyRentRoll,
          streetAddress: legacyRentRoll.propertyName,
          propertyType: "LegacyRentRoll",
        }))
      : [];

    const candidates = [
      ...personalTaxReturnData,
      ...businessTaxReturnData,
      ...rentRolls,
      ...legacyRentRollData,
    ]
      .filter((val) => val)
      // Filter out duplicate property Names
      .filter(
        (val, index, self) =>
          self.findIndex((t) => {
            const labelA = spreadablePropertyLabel(val as SpreadableProperty);
            const labelB = spreadablePropertyLabel(t as SpreadableProperty);
            return labelA === labelB;
          }) === index,
      ) as CanonicalRentRoll[];

    return candidates.length > 0 ? (candidates as SpreadableProperty[]) : null;
  },
);

export const mainCandidatesSelector = createSelector(
  [candidateAssetSelector],
  (candidates) => candidates?.map((candidate) => spreadablePropertyLabel(candidate)) ?? [],
);

export const ocrErrorMessageSelector = createSelector(
  [ocrStateSelector],
  (ocrState) => ocrState.error,
);
